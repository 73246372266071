import * as React from "react"

import Hero from '../hero';
import PricingBanner from '../pricing-banner';
import FeatureBlock from '../feature-block';
import FAQBlock from '../faq';
import PricingGrid from '../pricing-grid';
import IntegrationsBlock from '../integrations';
import BrowserBlock from '../browser-block';

const ComponentRouter = (data) => {

  return data.data.map((component, index) => {
    switch (component?.internal?.type) {
      case 'DatoCmsGlobeHero':
        return <Hero key={index} data={component} />
      case 'DatoCmsPricingBanner':
        return <PricingBanner key={index} data={component} />
      case 'DatoCmsFeatureBlock':
        return <FeatureBlock key={index} data={component} />
      case 'DatoCmsFaqBlock':
        return <FAQBlock key={index} data={component} />
      case 'DatoCmsPricingBlock':
        return <PricingGrid key={index} data={component} />
      case 'DatoCmsIntegrationsBlock':
        return <IntegrationsBlock key={index} data={component} />
      case 'DatoCmsBrowserBlock':
        return <BrowserBlock key={index} data={component} />
      default:
        return <React.Fragment />
  }});
};

export default ComponentRouter;