import * as React from "react"

import CodeImage from './code.png';

const Hero = () => {
  return (
    <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 pt-2 pb-5 my-4">

      <div className="grid lg:grid-cols-6 lg:gap-x-7 xl:gap-x-12 pt-5 pb-5">
        <div className="lg:col-span-3">
          <div className="max-w-2xl mb-8">
            <h2 className="text-3xl lg:text-4xl font-bold bg-gradient-to-r from-blue-800 via-blue-700 to-blue-600 bg-clip-text text-transparent">
              Built from the ground up to save engineering time
            </h2>
          </div>

          <p className="text-2xl text-blue-700">
            Save engineering time with unified payments functionality. We obsess over the maze of gateways, payments rails, and financial institutions that make up the global economic landscape so that your teams can build what you need on one platform.
          </p>

          <p className="text-2xl text-blue-700 pt-8">
            Save engineering time with unified payments functionality.
          </p>

          <div className="mt-7 grid gap-3 w-full sm:inline-flex">
            <a
              className="py-3 px-6 inline-flex justify-center items-center gap-x-2 text-sm font-semibold border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 rounded-3xl"
              href="https://docs.g10bal.com"
            >
              Read the documentation
              <svg
                className="flex-shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </a>
          </div>

          <div className='mt-4'>
            <a
              className="py-3 px-6 inline-flex justify-center items-center gap-x-2 text-sm font-semibold border border-blue-800 bg-white text-blue-600 hover:bg-blue-700 hover:text-white disabled:opacity-50 disabled:pointer-events-none rounded-3xl"
              href="/#pricing-section"
            >
              Create an account
              <svg
                className="flex-shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </a>
          </div>

        </div>
        {/* End Col */}
        <div className="lg:col-span-3 mt-10 lg:mt-0">
          <div className="border border-gray-200 shadow-2xl rounded-tl-lg rounded-tr-lg bg-gray-100">
            <div className="py-2 px-2 bg-gray-200 rounded-tl-md rounded-tr-md flex items-center">
              <div className="rounded-full h-4 w-4 bg-red-400 flex mr-3" />
              <div className="rounded-full h-4 w-4 bg-yellow-400 flex mr-3" />
              <div className="rounded-full h-4 w-4 bg-green-400 flex mr-3" />
              <div className="flex-auto px-2 py-1 ml-5 mr-1 rounded-md text-xs text-center">
                Visual Studio Code
              </div>
            </div>
            <div className="px-2 py-4 bg-black">
              <img
                className="w-full rounded-xl"
                src={CodeImage}
                alt="Image Description"
              />
            </div>
          </div>
        </div>
      </div>
      {/* End Col */}

    </div>
  );
}

export default Hero;
