import * as React from "react"
import { graphql, StaticQuery } from 'gatsby'

import Banner from '../components/banner'
import Hero from '../components/hero'
import Menu from '../components/menu'
import Integrations from '../components/integrations'
import CodeHero from '../components/browser-block'
import Footer from '../components/footer'
import PricingBanner from '../components/pricing-banner'
import FAQ from '../components/faq'
import PricingGrid from '../components/pricing-grid'
import ToolkitHero from '../components/feature-block'
import ComponentRouter from "../components/component-router";

function App() {
  return (
    <StaticQuery
      query={graphql`
      query MyQuery {
        allDatoCmsHomepage {
          nodes {
            components {
              ... on DatoCmsGlobeHero {
                id
                description
                subtitle
                title
                internal {
                  type
                }
              }
              ... on DatoCmsPricingBanner {
                id
                internal {
                  type
                }
                item2
                item2Subtext
                item3
                item3Subtext
                item4
                item4Subtext
                headlineItemSubtext
                headlineItem
              }
              ... on DatoCmsFeatureBlock {
                id
                internal {
                  type
                }
                title
                features {
                  title
                  featureType
                  description
                  image {
                    url
                  }
                }
              }
              ... on DatoCmsFaqBlock {
                id
                internal {
                  type
                }
                items {
                  answer
                  question
                }
              }
              ... on DatoCmsPricingBlock {
                id
                internal {
                  type
                }
              }
              ... on DatoCmsBrowserBlock {
                id
                internal {
                  type
                }
              }
              ... on DatoCmsIntegrationsBlock {
                id
                internal {
                  type
                }
                items {
                  logoBlock {
                    url
                  }
                  name
                }
              }
            }
          }
        }
      }               
      `}
      render={data => (
        <React.Fragment>
          <Banner />

          <Menu />

          <ComponentRouter data={data.allDatoCmsHomepage.nodes[0].components} />

          <Footer />
        </React.Fragment>

      )}
    />
  )
}

export default App
