import * as React from "react"

const Hero = ({ data }) => {
  console.log(data.items);
  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      {/* Title */}
      <div className="max-w-2xl mb-8 lg:mb-14">
        <h2 className="text-3xl lg:text-4xl font-bold bg-gradient-to-r from-blue-800 via-blue-700 to-blue-600 bg-clip-text text-transparent">
          Unlimited integrations to revolutionise your financial operations
        </h2>
      </div>
      {/* End Title */}
      {/* Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6">
        {data.items.map((item) => (
          <div className="p-4 md:p-7 bg-blue-100 rounded-xl">
            <img src={item.logoBlock.url} className='w-7/12 mx-auto h-auto rounded-lg' />
            <p className='text-center pt-1 text-lg text-blue-900 font-bold'>{ item.name }</p>
          </div>
        ))}
      </div>
      <div className='mt-8'>
        <a
          className="py-3 px-6 inline-flex justify-center items-center gap-x-2 text-sm font-semibold border border-blue-800 bg-white text-blue-600 hover:bg-blue-700 hover:text-white disabled:opacity-50 disabled:pointer-events-none rounded-3xl"
          href="#"
        >
          View all integrations
          <svg
            className="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </a>
      </div>
      {/* End Grid */}
    </div>
  );
}

export default Hero;
