import * as React from "react"
import createGlobe from 'cobe'

import { useEffect } from 'react';


const Hero = ({ data: { title, subtitle, description } }) => {
  useEffect(() => {
    let phi = 40
    let canvas = document.getElementById("cobe");
    
    const globe = createGlobe(canvas, {
      devicePixelRatio: 2,
      width: 3000 ,
      height: 3000,
      phi: 40,
      theta: 0,
      dark: 0,
      diffuse: 3,
      scale: 1,
      mapSamples: 36000,
      mapBrightness: 10,
      baseColor: [0.11764705882352941, 0.25098039215686274, 0.6862745098039216],
      markerColor: [0.11764705882352941, 0.25098039215686274, 0.6862745098039216],
      glowColor: [0.1450980392156863, 0.38823529411764707, 0.9215686274509803],
      offset: [50, 50],
      opacity: 0.1,
      markers: [
        { location: [51.5072, 0.1276], size: 0 },
        { location: [40.7128, -74.006], size: 0 },
      ],
      onRender: (state) => {
        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        state.phi = phi
        phi += 0.01
      },
    })

    return () => {
      globe.destroy()
    }
  }, []);

  return (
    <div className='relative overflow-hidden'>
      {/* Hero */}
      <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24">
        {/* Grid */}
        <div className="grid lg:grid-cols-6 lg:gap-x-7 xl:gap-x-12 lg:items-center pt-5 pb-5">
          <div className="lg:col-span-3">
            <h1 className="block text-6xl bg-gradient-to-r from-blue-800 via-blue-700 to-blue-600 bg-clip-text text-transparent">
              <strong>{ title }</strong> <br /> <small>{ subtitle }</small>
            </h1>
            <div className="pt-10 text-2xl text-blue-700" dangerouslySetInnerHTML={{__html: description}} />
            <div className="mt-7 grid gap-3 w-full sm:inline-flex">
              <a
                className="py-3 px-6 inline-flex justify-center items-center gap-x-2 text-sm font-semibold border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 rounded-3xl"
                href="/#pricing-section"
              >
                Get started
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </a>
              <a
                className="py-3 px-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium border border-blue-800 bg-white text-blue-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none rounded-3xl"
                href="#"
              >
                Contact sales team
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </a>
            </div>

          </div>
          {/* End Col */}
          <div className="lg:col-span-3 mt-10 lg:mt-0" />
          {/* End Col */}
        </div>
        {/* End Grid */}
      </div>
      {/* End Hero */}
          <canvas
                  id="cobe"
                  style={{width: '50vw', height: '100vh', margin: '0 auto', position: 'absolute', top: '0', right: '0'}}
                  width="1000"
                  height="1000"
                />
    </div>
  );
}

export default Hero;
