import * as React from "react"

const Hero = (
  { 
    data: { 
      headlineItem,
      headlineItemSubtext,
      item2,
      item2Subtext,
      item3,
      item3Subtext,
      item4,
      item4Subtext,
    } 
  }
) => {

  
  return (
    <div className="bg-gradient-to-r from-blue-800 via-slate-700 to-slate-600">
    {/* Features */}
    <div className=" max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      {/* Grid */}
      <div className="grid items-center lg:grid-cols-12 gap-6 lg:gap-12">
        <div className="lg:col-span-4">
          {/* Stats */}
          <div className="lg:pr-6 xl:pr-12">
            <p className="text-6xl font-bold leading-10 text-white">
              { headlineItem }
            </p>
            <p className="mt-2 sm:mt-3 text-white">
              { headlineItemSubtext }
            </p>
          </div>
          {/* End Stats */}
        </div>
        {/* End Col */}
        <div
          className="lg:col-span-8 relative lg:before:absolute lg:before:top-0 lg:before:-left-12 lg:before:w-px lg:before:h-full lg:before:bg-white">
          <div className="grid gap-6 grid-cols-2 md:grid-cols-4 lg:grid-cols-3 sm:gap-8">
            {/* Stats */}
            <div>
              <p className="text-3xl font-semibold text-white">{ item2 }</p>
              <p className="mt-1 text-white">{ item2Subtext }</p>
            </div>
            {/* End Stats */}
            {/* Stats */}
            <div>
              <p className="text-3xl font-semibold text-white">{ item3 }</p>
              <p className="mt-1  text-white">{ item3Subtext }</p>
            </div>
            {/* End Stats */}
            {/* Stats */}
            <div>
              <p className="text-3xl font-semibold text-white">{ item4 }</p>
              <p className="mt-1 text-white">{ item4Subtext }</p>
            </div>
            {/* End Stats */}
          </div>
        </div>
        {/* End Col */}
      </div>
      {/* End Grid */}
    </div>
    {/* End Features */}
  </div>
  );
}

export default Hero;
