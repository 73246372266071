import * as React from "react"

import { BeakerIcon } from '@heroicons/react/24/solid'

const renderimage = (feature) => {
  if (feature?.image?.url) {
    return (
      <div className="h-auto flex flex-col justify-center items-center bg-slate-100 rounded-t-xs my-1 mx-1">
        <img
          className="h-100 w-100 object-cover"
          src={feature.image.url}
        />
      </div>
    )
  }

  return (
    <div className="h-36 flex flex-col justify-center items-center bg-slate-100 rounded-t-xs my-1 mx-1">
      <BeakerIcon className="h-24 w-24 text-slate-700" />
    </div>
  );
}

const Hero = ({ data: { title, features } }) => {
  return (
    <>
      {/* Card Blog */}
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-16 mx-auto">
        <h2 className="block text-2xl font-bold sm:text-4xl md:text-3xl lg:text-4xl bg-gradient-to-r from-blue-800 via-blue-700 to-blue-600 bg-clip-text text-transparent">
          {title}
        </h2>

        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-12 mt-16">

          {features.map((feature, index) => (
            <div
              className="group flex flex-col h-full bg-white border border-gray-150 shadow-lg rounded-xs pb-4 relative">
              {renderimage(feature)}
              <div className="p-4 md:p-6">

                <span className="block mb-1 text-xs font-bold uppercase text-blue-800">
                  {feature.featureType}
                </span>
                <h3 className="text-2xl font-semibold bg-gradient-to-r from-blue-800 via-blue-700 to-blue-600 bg-clip-text text-transparent">
                  {feature.title}
                </h3>
                <p className="mt-3 text-slate-500 text-md font-light">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}

        </div>
      </div>
    </>
  );
}

export default Hero;
